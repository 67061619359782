import React, { useState } from "react";
import {
  Typography,
  Modal,
  Box,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Button,
  useMediaQuery,
} from "@mui/material";
import IconConstants from "../../utils/IconConstants";

const addOrRemovePerson = [
  {
    name: "Add Person",
    icon: IconConstants.AddPerson,
    activeIcon: IconConstants.AddPersonActive,
    bgColor: "#60B65E",
  },
  {
    name: "Remove Person",
    icon: IconConstants.RemovePerson,
    activeIcon: IconConstants.RemovePersonActive,
    bgColor: "#ED705B",
  },
];

const ActionOptions = ({
  anchorEl,
  menuOpen,
  handleClose,
  room,
  menuIsNearRightEdge,
  handleOpenPersonPopover = () => {},
  modalName
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <Popover
        id={menuOpen ? "action-room-menu" : undefined}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: menuIsNearRightEdge ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuIsNearRightEdge ? "right" : "left",
        }}
        sx={{
          ml: "0.3%",
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: room.bgColor,
            width: { xs: "44.36vw", sm: "20.744vw", md: "12.014vw" },
            paddingY: "5%",
          }}
        >
          <Box
            sx={{
              width: "86.706%",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              rowGap: "0.7vw",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 1,
                columnGap: { xs: "1vw", sm: "0.5vw" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 800,
                  color:
                    room.actionBtnName === "Check In" ? "#FFFFFF" : "#000000",
                  fontSize: { xs: "4.10vw", sm: "1.92vw", md: "1.12vw" },
                }}
              >
                {room.roomNo}
              </Typography>
              <Box sx={{ width: { xs: "4.7vw", sm: "2.16vw", md: "1.25vw" } }}>
                <img src={room?.isCleanIcon} style={{ width: "100%" }} />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "2.56vw", sm: "1.2vw", md: "0.69vw" },
                  fontWeight: 600,
                  color: room?.color,
                  textTransform: "uppercase",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {room.roomData.name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection:
                  room?.flag == "under_maintenance"
                    ? "column-reverse"
                    : "column",
                rowGap: { xs: "1vw", sm: "0.7vw" },
              }}
            >
                <Box
                  sx={{
                    backgroundColor: room.btnColor,
                    border: `0.5px solid ${room.borderColor}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "4px",
                    padding: "3% 0",
                    width: "100%",
                    columnGap: "0.5vw",
                  }}
                  onClick={room.actionBtnOnClick}
                >
                  <img src={room.actionBtnIcn} />
                  <Typography
                    sx={{
                      fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                      color: room.btnTextColor,
                      fontWeight: 600,
                    }}
                  >
                    {room?.actionBtnName}
                  </Typography>
                </Box>
                
              {room?.actionBtnName === "Check Out" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {addOrRemovePerson.map((v, ind) => (
                    <Button
                      onMouseEnter={() => setHoveredIndex(ind)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={()=>
                        handleOpenPersonPopover(v.name)}
                      onClose={() => {}}
                      action-room-menu
                      key={v.name}
                      disabled={
                        room?.roomData?.no_of_extra_persons === 0 &&
                        v.name === "Remove Person"
                      }
                      sx={{
                        p: { sm: "2.34% 0", md: "2.25% 0" },
                        display: "flex",
                        alignItems: "center",
                        width: { sm: "47.34%", md: "45.62%" },
                        backgroundColor:
                          modalName === v.name ? v.bgColor : "#FFFFFF",
                        ":hover": { backgroundColor: v.bgColor },
                        transition: "background-color 0.3s ease-in-out",
                        border: `0.5px solid ${v.bgColor}`,
                        borderRadius: "4px",
                        minWidth: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          height: { sm: "2.398vw", md: "1.389vw" },
                          width: { sm: "2.398vw", md: "1.389vw" },
                        }}
                      >
                        <img
                          src={
                            modalName === v.name || hoveredIndex == ind
                              ? v.activeIcon
                              : v.icon
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            transition: "opacity 0.3s ease-in-out",
                            opacity:
                              modalName === v.name || hoveredIndex == ind
                                ? 0.9
                                : 1,
                          }}
                          alt=""
                        />
                      </Box>
                    </Button>
                  ))}
                </Box>
              )}

              {room?.flag != "under_maintenance" ? (
                <Paper
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "8px",
                  }}
                >
                  <MenuList id="options-menu">
                    {room?.options
                      ?.filter((opt) => opt !== null)
                      .map((opt, menuInd) => {
                        return (
                          <MenuItem
                            key={menuInd}
                            onClick={(e) => {
                              opt.optClick();
                            }}
                            sx={{
                              fontSize: {
                                xs: "3.08vw",
                                sm: "1.44vw",
                                md: "0.83vw",
                              },
                              fontWeight: 600,
                              color: "#252525",
                              paddingY: "3.5%",
                              minHeight: 0,
                            }}
                          >
                            {opt.name}
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Paper>
              ) : (
                <Box
                  sx={{
                    height: { xs: 88, sm: "10.56vw", md: "6.11vw" },
                    backgroundColor: "#E7E7E7",
                    borderRadius: "8px",
                    padding: "5%",
                    flexWrap: "wrap",
                    overflow: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                      fontWeight: 500,
                      color: "#585858",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    {room?.roomData?.maintenance_reason}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
export default ActionOptions;
