import { useCallback, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Button,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { END_POINT } from "../../utils/End-points";
import ApiClient from "../../apis/Api-client";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectTriggered,
  setTriggered,
} from "../../core/store/redux/slices/DashboardApi-slice";
import { errorToster, successToster } from "../../shared/toster/Toster";
import { Cancel, Edit } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { Helper } from "../../utils/Helpers";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  OCCUPANCY_STATUSES,
  RESERVATION_STATUSES,
  TAX_PERCENT,
} from "../../utils/Constants";
import { format } from "date-fns";
const { TENTATIVE, CONFIRMED } = RESERVATION_STATUSES;
const {
  getLocalTimeFromUtc,
  getBasePriceFromFomNetRent,
  getNetRetFromBasePrice,
  debounce,
} = new Helper();

const RoomShift = ({
  bookingData,
  resetData = () => {},
  handleCloseModal = () => {},
  handleClosePopover = () => {},
}) => {
  const { api } = ApiClient();
  const themeStyles = useTheme();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;
  const isTriggered = useSelector(selectTriggered);
  const dispatch = useDispatch();
  const [availableRoomsByType, setAvailableRoomsByType] = useState([]);
  const [reservationStatuses, setReservationStatuses] = useState([]);
  const [roomShiftReasons, setRoomShiftReasons] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [roomRentDisable, setRoomRentDisable] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isOccupancyRoomShift =
    bookingData?.booking_status === OCCUPANCY_STATUSES.CHECK_IN;

  const formConfig = () => {
    const getRoomType = (room_type_id) =>
      roomTypes.find((v) => v.room_type_id === room_type_id);

    return Yup.object().shape({
      room_type_id: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Room type is required"),
      room_id: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please select room no"),
      no_of_adults: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Number of adults is required")
        .min(1, "Minimum of 1 adult should be selected")
        .test(
          "max-adults",
          "Number of adults exceeds the limit",
          function (value) {
            const { room_type_id } = this.parent;
            const roomType = getRoomType(room_type_id);
            if (roomType && value > roomType.max_adult) {
              return this.createError({
                message: `Only ${roomType.max_adult} adults permitted for the selected room type`,
              });
            }
            return true;
          }
        ),
      no_of_children: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .notRequired()
        .test(
          "max-children",
          "Number of children exceeds the limit",
          function (value) {
            const { room_type_id } = this.parent;
            const roomType = getRoomType(room_type_id);
            if (roomType && value > roomType.max_children) {
              return this.createError({
                message: `Only ${roomType.max_children} children permitted for the selected room type`,
              });
            }
            return true;
          }
        ),
      room_rent: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(1, "Please enter a valid rent")
        .required("Please enter room rent"),
      room_shift_reason_id: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .test(
          "is-required-if-occupancy",
          "Please select reason",
          function (value) {
            return isOccupancyRoomShift ? !!value : true;
          }
        ),
    });
  };

  const methods = useForm({
    resolver: yupResolver(formConfig()),
    defaultValues: {
      room_type_id: bookingData?.room_type_id,
      room_no: bookingData?.room_no,
      inc_of_tax: bookingData?.inc_of_tax,
      no_of_adults: bookingData?.no_of_adults,
      no_of_children: bookingData?.no_of_children,
      room_rent: bookingData?.inc_of_tax
        ? getNetRetFromBasePrice(
            bookingData.room_rent,
            1,
            bookingData.total_tax_rate
          )
        : bookingData.room_rent,
    },
  });

  const { control, watch, reset, setValue, getValues, setError, handleSubmit } =
    methods;

  const {
    name,
    checkin_date,
    checkout_date,
    inc_of_tax,
    occupancy_key,
    reservation_id,
    stay_type_id,
  } = bookingData;

  const {
    room_type_id,
    room_id,
    no_of_adults,
    no_of_children,
    room_shift_reason_id,
  } = watch();

  useEffect(() => {
    setInitialData();
  }, []);

  const setInitialData = async () => {
    try {
      const [roomTypes, reservationStatus, roomShiftReasons] =
        await Promise.all([
          api
            .get(END_POINT.ROOM_TYPE.GET_ALL_ROOM_TYPE)
            .then((res) => res?.data?.data),
          api
            .get(END_POINT.RESERVATION.GET_ALL_RESERVATION_STATUS)
            .then((res) => res?.data?.data),
          api
            .get(END_POINT.ROOM.GET_ALL_ROOM_SHIFT_REASON)
            .then((res) => res?.data?.data),
        ]);
      setRoomTypes(roomTypes);
      setReservationStatuses(reservationStatus);
      setRoomShiftReasons(roomShiftReasons);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (e, options) => {
    const { name, value, type } = e.target;
    if (type != "number" || name === "room_rent") {
      setError(name, null);
    }
    setValue(name, value);

    if (name === "room_type_id") {
      const { room_base_price, room_type_name, max_adult, max_children } =
        roomTypes?.find((type) => type?.room_type_id === value);
      const commonData = {
        room_type_id: value,
        inc_of_tax: inc_of_tax,
        room_id: null,
        no_of_children,
        no_of_adults,
        room_type_name,
        room_shift_reason_id,
      };

      if (value === bookingData?.room_type_id) {
        reset(
          {
            ...commonData,
            room_rent: inc_of_tax
              ? getNetRetFromBasePrice(
                  bookingData.room_rent,
                  1,
                  bookingData.total_tax_rate
                )
              : bookingData.room_rent,
          },
          { keepErrors: true }
        );
      } else {
        reset(
          {
            ...commonData,
            room_rent: room_base_price,
          },
          { keepErrors: true }
        );
      }

      if (no_of_adults > max_adult) {
        setError("no_of_adults", {
          type: "manual",
          message: `Only ${max_adult} adults are permitted`,
        });
      } else if (!!no_of_adults) {
        setError("no_of_adults", null);
      }

      if (no_of_children > max_children) {
        setError("no_of_children", {
          type: "manual",
          message: `Only ${max_children} children permitted`,
        });
      } else if (!!no_of_children) {
        setError("no_of_children", null);
      }

      setAvailableRoomsByType([]);
    } else if (name === "room_id") {
      setValue(
        "room_no",
        options?.find((option) => option?.value === value)?.label
      );
    } else if (name === "no_of_adults") {
      const maxAdult = roomTypes.find(
        (v) => v.room_type_id === room_type_id
      )?.max_adult;
      if ((value > maxAdult) & !!getValues("room_type_id")) {
        setError("no_of_adults", {
          type: "manual",
          message: `Only ${maxAdult} adults are permitted`,
        });
      } else {
        setError("no_of_adults", null);
      }
    } else if (name === "no_of_children") {
      const maxChild = roomTypes.find(
        (v) => v.room_type_id === room_type_id
      )?.max_children;
      if ((value > maxChild) & !!getValues("room_type_id")) {
        setError("no_of_children", {
          type: "manual",
          message: `Only ${maxChild} children permitted`,
        });
      } else {
        setError("no_of_children", null);
      }
    }
  };

  useEffect(() => {
    if (!!room_type_id) {
      setAvailableRoomsData(room_type_id);
    }
  }, [room_type_id]);

  const setAvailableRoomsData = async (roomTypeId) => {
    try {
      const res = await api.post(
        isOccupancyRoomShift
          ? END_POINT.ROOM.GET_AVAILABLE_ROOMS
          : END_POINT.ROOM.GET_AVAILABLE_ROOMS_TO_RESERVE,
        {
          checkin_date: getLocalTimeFromUtc(checkin_date)?.dateTimeString,
          checkout_date: getLocalTimeFromUtc(checkout_date)?.dateTimeString,
          room_type_id: roomTypeId,
        }
      );
      if (res?.status === 200) {
        const availableRooms = res?.data?.data.filter(
          (room) => room.room_id !== bookingData?.room_id
        );
        setAvailableRoomsByType(availableRooms);
      }
    } catch (error) {
      setAvailableRoomsByType([]);
    }
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    let sanitizedValue = value.replace(/[^0-9]/g, "");
    // Prevent multiple leading zeros
    if (sanitizedValue.length > 1 && sanitizedValue.startsWith("0")) {
      sanitizedValue = sanitizedValue.replace(/^0+/, "");
    }
    e.target.value =
      name !== "no_of_children" && sanitizedValue === "0"
        ? null
        : sanitizedValue;
  };

  const debouncedhandleRoomShift = useCallback(
    debounce((data) => {
      handleRoomShift(data);
    }, 500),
    []
  );

  const handleRoomShift = async (formData) => {
    setIsSubmitted(true);
    const {
      room_type_id,
      room_id,
      room_shift_reason_id,
      no_of_adults,
      no_of_children,
      room_rent,
    } = formData ?? {};

    const booking_price = getBasePriceFromFomNetRent(
      room_rent,
      inc_of_tax,
      TAX_PERCENT
    );
    try {
      let response;
      if (isOccupancyRoomShift) {
        response = await api.post(
          `${END_POINT.ROOM.ROOM_SHIFT}/${occupancy_key}`,
          {
            room_type_id,
            room_id,
            booking_price,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            room_shift_reason_id,
            no_of_adults,
            no_of_children,
          }
        );
      } else {
        response = await api.post(END_POINT.ROOM.RESERVATION_ROOM_SHIFT, {
          reserved_room_type_id: room_type_id,
          reserved_room_id: room_id,
          reservation_id,
          stay_type_id,
          reservation_price: booking_price,
          expected_no_of_adults: no_of_adults,
          expected_no_of_children: no_of_children,
        });
      }

      if (response?.status === 200) {
        handleClosePopover();
        successToster(
          <>
            Room Shifted for <span style={{ fontWeight: 700 }}>{name}</span>{" "}
            from room{" "}
            <span style={{ fontWeight: 700 }}>{bookingData?.room_no}</span> to
            room <span style={{ fontWeight: 700 }}>{getValues("room_no")}</span>
            .
          </>
        );
        dispatch(setTriggered(!isTriggered));
        resetData();
      }
    } catch (error) {
      errorToster(error?.response?.data?.msg || "Something went wrong!");
    } finally {
      setTimeout(() => {
        setIsSubmitted(false);
      }, 2000);
    }
  };

  const fields = [
    {
      label: "Room Type",
      name: "room_type_id",
      type: "select",
      size: "small",
      value: room_type_id,
      width: { xs: "100%", sm: "48%" },
      options: roomTypes?.map((val, ind) => ({
        value: val.room_type_id,
        label: val.room_type_name,
      })),
      noOptionsLabel: "No Room Types Available",
    },
    {
      label: "Room No",
      name: "room_id",
      type: "select",
      size: "small",
      value: room_id,
      width: { xs: "100%", sm: "48%" },
      options: availableRoomsByType?.map((room, ind) => ({
        value: room.room_id,
        label: room.room_no,
        status: room.reservation_status_code,
      })),
      noOptionsLabel: "No Rooms Available",
    },
    {
      label: "Number of Adults",
      name: "no_of_adults",
      type: "number",
      size: "small",
      width: { xs: "100%", sm: "48%" },
    },
    {
      label: "Number of Children",
      name: "no_of_children",
      type: "number",
      size: "small",
      width: { xs: "100%", sm: "48%" },
    },
    {
      label: "Room Rent",
      name: "room_rent",
      type: "number",
      size: "small",
      width: { xs: "100%" },
      disable: roomRentDisable,
      inputAdornment: (
        <InputAdornment position="end">
          <IconButton
            size="small"
            onClick={() => setRoomRentDisable(!roomRentDisable)}
          >
            {roomRentDisable ? (
              <Edit fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      ),
    },
    {
      label: "Inc Of Tax",
      type: "checkbox",
      disable: true,
      name: "inc_of_tax",
      width: { xs: "100%" },
    },
    {
      label: "Select Room Shift Reason",
      name: "room_shift_reason_id",
      type: "select",
      size: "small",
      value: room_shift_reason_id,
      width: { xs: "100%" },
      options: roomShiftReasons?.map((reason, ind) => ({
        value: reason.room_shift_reason_id,
        label: reason.reason,
      })),
      noOptionsLabel: "No Reasons Available",
    },
  ];

  return (
    <Box
      sx={{
        width: {
          xs: "70vw",
          md: "41vw",
        },
        padding: { xs: "2% 0 3% 0", md: "0.5% 0 1.5% 0" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0 auto 6% auto",
          width: "94%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "3.59vw", sm: "2.398vw", md: "1.39vw" },
          }}
        >
          Room Shift
        </Typography>
        <Typography
          sx={{ fontSize: { md: "1.12vw" }, color: "#D9A62E", fontWeight: 500 }}
        >
          {bookingData?.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "94%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ width: "10%" }}>
          <Box
            sx={{
              backgroundColor: "#F7F2E8",
              borderRadius: "50%",
              width: { xs: "10vw", sm: "5vw", md: "2.92vw" },
              height: {
                xs: "10vw",
                sm: "5vw",
                md: "2.92vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
                color: "#252525",
                fontWeight: 600,
              }}
            >
              {bookingData?.room_no}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "88%" }}>
          <form onSubmit={handleSubmit(debouncedhandleRoomShift)}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                width: 1,
              }}
            >
              {fields
                .filter((field) =>
                  isOccupancyRoomShift
                    ? field
                    : field.name !== "room_shift_reason_id"
                )
                .map((roomField) => (
                  <Controller
                    {...methods}
                    control={control}
                    name={roomField.name}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => {
                      return roomField.type === "select" ? (
                        <FormControl sx={{ width: roomField?.width }}>
                          <TextField
                            {...field}
                            autoComplete="off"
                            select
                            name={roomField?.name}
                            size={roomField?.size}
                            label={roomField?.label}
                            disabled={roomField.disable}
                            onChange={(e) =>
                              handleChange(e, roomField?.options)
                            }
                            InputLabelProps={{
                              sx: {
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.92vw",
                                  md: "1.12vw",
                                },
                                color: "#A3A3A3",
                              },
                            }}
                            InputProps={{
                              sx: {
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.92vw",
                                  md: "1.12vw",
                                },
                              },
                            }}
                            FormHelperTextProps={{
                              sx: {
                                fontSize: {
                                  xs: "3.08vw",
                                  sm: "1.44vw",
                                  md: "0.83vw",
                                },
                              },
                            }}
                            error={!!error?.message}
                            helperText={error ? error?.message : ""}
                          >
                            {roomField?.options?.length > 0 ? (
                              roomField?.options?.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  disabled={
                                    roomField.name === "room_id" &&
                                    [TENTATIVE, CONFIRMED].includes(
                                      reservationStatuses.find(
                                        (status) =>
                                          status.reservation_status_code ===
                                          option.status
                                      )?.reservation_status_code
                                    )
                                  }
                                  sx={{
                                    backgroundColor:
                                      roomField.name === "room_id"
                                        ? [TENTATIVE, CONFIRMED].includes(
                                            reservationStatuses.find(
                                              (status) =>
                                                status.reservation_status_code ===
                                                option.status
                                            )?.reservation_status_code
                                          )
                                          ? "#9747FF"
                                          : "#D9FFD8"
                                        : "",
                                    fontSize: {
                                      xs: "4.1vw",
                                      sm: "1.92vw",
                                      md: "1.12vw",
                                    },
                                  }}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="" disabled>
                                {roomField.noOptionsLabel}
                              </MenuItem>
                            )}
                          </TextField>
                        </FormControl>
                      ) : roomField.type === "checkbox" ? (
                        <FormControl sx={{ width: roomField?.width }}>
                          <FormControlLabel
                            {...field}
                            sx={{
                              marginLeft: "auto",
                              "& .MuiFormControlLabel-label": {
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.92vw",
                                  md: "1.1vw",
                                },
                              },
                            }}
                            value={inc_of_tax}
                            name={roomField.name}
                            disabled={roomField.disable}
                            control={
                              <Checkbox checked={field?.value} size="small" />
                            }
                            label={roomField.label}
                            labelPlacement="end"
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          sx={{ width: roomField?.width, marginTop: "6%" }}
                        >
                          <TextField
                            {...field}
                            name={roomField.name}
                            variant="outlined"
                            className="NoSpinnerTextField no-spinner"
                            onWheel={(e) => e.target.blur()}
                            value={getValues(roomField.name)}
                            type={roomField.type}
                            size={roomField?.size}
                            label={roomField?.label}
                            inputProps={{
                              min: 0,
                              maxLength: !!roomField.maxLength
                                ? roomField.maxLength
                                : "",
                              max: !!roomField.max ? roomField.max : "",
                            }}
                            InputProps={{
                              endAdornment: roomField?.inputAdornment,
                              sx: {
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.92vw",
                                  md: "1.12vw",
                                },
                                backgroundColor:
                                  roomField?.name === "total_price" &&
                                  "#EBEBEB",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                fontSize: {
                                  xs: "4.1vw",
                                  sm: "1.92vw",
                                  md: "1.12vw",
                                },
                                color: "#A3A3A3",
                              },
                            }}
                            FormHelperTextProps={{
                              sx: {
                                fontSize: {
                                  xs: "3.08vw",
                                  sm: "1.44vw",
                                  md: "0.83vw",
                                },
                              },
                            }}
                            onInput={(e) => handleInput(e)}
                            onChange={(e) => handleChange(e)}
                            disabled={roomField.disable}
                            error={!!error?.message}
                            helperText={error ? error?.message : ""}
                          />
                        </FormControl>
                      );
                    }}
                  />
                ))}

              <Button
                onClick={handleCloseModal}
                variant="outlined"
                sx={{
                  width: "48%",
                  color: primaryColor,
                  border: `1px solid ${primaryColor}`,
                  fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                  fontWeight: 700,
                  marginTop: "5%",
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                disabled={room_id === bookingData?.room_id || isSubmitted}
                type="submit"
                sx={{
                  width: "48%",
                  color: contrastText,
                  backgroundColor: primaryColor,
                  fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.12vw" },
                  fontWeight: 700,
                  marginTop: "5%",
                }}
              >
                CONFIRM
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomShift;
