import {
  eachDayOfInterval,
  format,
  startOfDay,
  endOfDay,
  addMinutes,
  parse,
  parseISO,
  addHours,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import moment from "moment";
import { STAY_TYPES, TAXES } from "./Constants";
import numWords from "num-words";
import { decodeJwt } from "jose";

const Decimal = require("decimal.js");

export class Helper {
  async handleFormValidation(data, context, validationSchema, setError) {
    try {
      // Validate the entire form using Yup schema
      await validationSchema.validate(data, { abortEarly: false });
      return {
        values: data,
        errors: {},
      };
    } catch (validationErrors) {
      // Map Yup validation errors to react-hook-form errors
      const mappedErrors = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = {
          type: error.type || "validation",
          message: error.message,
        };
        return acc;
      }, {});

      // Set errors using setError
      Object.keys(mappedErrors).forEach((field) => {
        setError(field, mappedErrors[field]);
      });

      return {
        values: {},
        errors: mappedErrors,
      };
    }
  }

  getDate() {
    const startDate = () => {
      return format(startOfDay(new Date()), "yyyy-MM-dd HH:mm:ss");
    };

    const endDate = () => {
      return format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss");
    };

    const getTimeFromDate = (dateString) => {
      try {
        const date = new Date(dateString);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const isPM = hours >= 12;
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${
          isPM ? "PM" : "AM"
        }`;
      } catch (error) {
        console.error(`Error Converting Given Date: ${dateString} :`, error);
      }
    };

    const formatDateString = (date, format) => {
      let formattedDate;
      try {
        let dateObject = date instanceof Date ? date : new Date(date);
        switch (format) {
          case "dd-mm-yyyy":
            formattedDate = `${dateObject
              .getDate()
              .toString()
              .padStart(2, "0")}-${(dateObject.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${dateObject.getFullYear()}`;
            break;
          default:
            formattedDate =
              "Enter A Valid Date Format Available Formats:[dd-mm-yyyy]";
        }
        return formattedDate;
      } catch (error) {
        console.error("Error Formatting Date:", error?.message || error);
      }
    };

    const calculateDaysDifference = (startDateString, endDateString) => {
      try {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        if (startDate > endDate) {
          throw new Error("Start date must be less than or equal to end date");
        }
        let daysDifference = Math.ceil(
          (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
        );
        // if (daysDifference == 0) { daysDifference = 1 }
        return daysDifference + 1;
      } catch (error) {
        console.error(
          "Error Calculating Day Difference:",
          error?.message || error
        );
      }
    };

    return {
      date: {
        startDate,
        endDate,
        getTimeFromDate,
        formatDateString,
        calculateDaysDifference,
      },
    };
  }

  roundOff = (value, decimals = 2) => {
    return Math.round(value);
  };

  getNetRetFromBasePrice = (basePrice, stayDays, taxPercentage) => {
    try {
      const modifiedBasePrice = this.truncateNumberByDecimal(basePrice);
      const taxAmount =
        modifiedBasePrice * this.truncateNumberByDecimal(taxPercentage / 100);
      if ((!modifiedBasePrice, !stayDays, !taxPercentage)) {
        throw new Error(
          "basePrice or stayDays or taxPercentage is missing to calculate room rent"
        );
      }
      return this.roundOff((modifiedBasePrice + taxAmount) * stayDays);
    } catch (error) {
      throw error;
    }
  };

  getBasePriceFromFomNetRent = (netRent, incOfTax, taxPercentage) => {
    let basePrice;
    if (!incOfTax) {
      basePrice = this.truncateNumberByDecimal(Number(netRent));
    } else {
      basePrice =
        this.truncateNumberByDecimal(Number(netRent)) /
        (1 + this.truncateNumberByDecimal(taxPercentage / 100));
    }

    return this.truncateNumberByDecimal(basePrice);
  };

  truncateNumberByDecimal(value, decimal = 2) {
    return (
      Number((value * 10 ** decimal).toString().split(".")[0]) / 10 ** decimal
    );
  }

  formatDate = (date) => {
    return date.toISOString().slice(0, 19).replace("T", " ") + ".000Z";
  };

  getDaysOfYear(year) {
    // Create an array of all days in the year
    const startOfYear = new Date(year, 0, 1);
    const endOfYear = new Date(year, 11, 31);
    const allDaysOfYear = eachDayOfInterval({
      start: startOfYear,
      end: endOfYear,
    });

    // Format each date in the array
    const formattedDaysOfYear = allDaysOfYear.map((date) =>
      format(date, "EEE dd MMM")
    );

    return formattedDaysOfYear;
  }

  isValidDate(inputDate) {
    if (!inputDate) return false; // Handle cases where inputDate is null or undefined
    const isValidDate = moment(inputDate, true).isValid();
    if (!isValidDate) return false; // If date is not valid, return false
    const year = moment(inputDate).year();
    const isValidYear = /^\d{1,4}$/.test(year.toString()); // Check if year has 1 to 4 digits
    return isValidYear; // Return whether year is valid or not
  }

  getSumPreviousAmount = (payments, targetPaymentId) => {
    try {
      let sum = 0;
      for (const payment of payments) {
        if (payment.payment_id < targetPaymentId) {
          sum += payment.paid_amount;
        } else if (payment.payment_id === targetPaymentId) {
          break;
        }
      }
      return sum;
    } catch (error) {
      console.error("Error getting sum of previous amount:", error?.message);
    }
  };

  viewPaymentReceipt = (billData) => {
    let printableTemplate = this.generateReceiptTemplate(billData);
    const printWindow = window.open("", "Hotel_Receipt");
    printWindow.document.body.innerHTML = printableTemplate;
  };

  printPaymentReceipt = (billData) => {
    if (billData?.billHeader === "ADVANCE RECEIPT") {
      billData.previousPaidAmount = this.getSumPreviousAmount(
        billData?.paymentData,
        billData?.paymentDetail?.payment_id
      );
    }
    let printableTemplate = this.generatePrintTemplate(billData);

    const printWindow = window.open("", "Print Bill");
    printWindow.document.write(printableTemplate);
    const img = printWindow.document.getElementById("hotel-logo-image");

    img.onload = () => {
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  generateReceiptTemplate = (data) => {
    const template = `
    <!DOCTYPE html>
    <html lang="en">

    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <link rel="icon" href="./favicon.ico" type="image/x-icon" />
      <style>
         @media print {
            .received-amount div {
                background-color: #f0f0f0 !important;
                -webkit-print-color-adjust: exact; /* For Safari and Chrome */
                print-color-adjust: exact; /* For Firefox */
            }
        }

        .dotted-underline {          
          border-bottom: 1px dotted black;
        }

        body {
          font-family: Arial, sans-serif;
          background-color: #ffffff;
        }

        /* CSS Variables for Colors */
        :root {
          --primary-color: #3a3a3a;
          --secondary-color: #3a3a3a;
          --tertiary-color: #858585;
          --border-color: #d7d7d7;
          --background-color: #F3F3F3;
        }

        .invoice-table {
          width: 100%;
          border: 1px solid #e9e9e9;
          border-collapse: collapse;
          font-size: 12px;
          margin-top: 5%;
        }

        .invoice-table th,
        .invoice-table td {
          border: 1px solid #e9e9e9;
          padding: 5px;
          text-align: left;
        }

        .invoice-table th {
          background-color: var(--primary-color);
          color: #ffffff;
          font-weight: 500;
        }
      </style>
    </head>

    <body>
      <div style="height: 97.2vh; width: 90%; margin: 0 auto;">
        <div style="
              width: 90%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 1% auto 0 auto;
            ">
          <div style="width: 65%">
            <img data-testid='receipt-hotel-logo' id="hotel-logo-image" src=${
              data?.userData?.hotel_logo
            } alt="" height="70px" width="70px"
              style="border-radius: 3px" />
            <p data-testid ='receipt-hotel-name' style="font-size: 16px; font-weight: 700; color: var(--primary-color)">
              ${data?.userData?.hotel_name}
            </p>
            <p data-testid ='receipt-hotel-address' style="color: #3a3a3a; font-size: 12px; font-weight: 400; max-width: 200px; line-height: 1.5;">
              ${data?.userData?.hotel_address}
            </p>
            <div>
              <p data-testid ='receipt-hotel-contact-number' style="color: #3a3a3a; font-size: 12px; font-weight: 400; margin: 0">
                ${data?.userData?.hotel_contact_number}
              </p>
            </div>
            <div data-testid ='receipt-hotel-gstin-number' style="color: #3a3a3a; font-size: 12px; font-weight: 400">
              <p>GSTIN : ${
                data?.userData?.hotel_gstin_number
                  ? data?.userData.hotel_gstin_number
                  : "-"
              }</p>
            </div>
          </div>
          <div style="width: 35%;">
            <p data-testid ='receipt-hotel-payment-receipt' style="font-size: 20px; font-weight: 500; color: var(--primary-color);margin:0;">
              Payment Receipt
            </p>
            <p data-testid ='receipt-hotel-receipt-number-label' style="font-size: 12px; font-weight: 500">
              Receipt.No. : ${data?.bookingData?.receipt_number || "-"}
            </p>
            <p data-testid ='receipt-hotel-payment-date-label' style="font-size: 12px; font-weight: 500">
              Payment Date : <span>${
                this.getLocalTimeFromUtc(
                  data?.bookingData?.payment_date?.split(".")[0]
                ).invoice_date
              }</span>
            </p>
          </div>
        </div>

        <div style="width: 90%; margin: 0 auto">
          <hr style="border: 1px solid #d7d7d7" />
          <div class="parent-body" style="display: flex; flex-direction: column; padding-top: 16px;">
            <div class="billto-details" style="display: flex; flex-direction: column; gap: 16px;">
              <div class="customer-name" style="display: flex; justify-content: flex-start; align-items: center;">
                <p style="width: 40%; font-size: 12px; font-weight: 400px; margin: 0;">Customer Name</p>
                <p class="dotted-underline"
                  style="width: 50%; font-size: 12px; font-weight: 700; margin: 0; color: var(--primary-color);">
                  ${data?.bookingData.name?.replace(/\b\w/g, (char) =>
                    char.toUpperCase()
                  )}</p>
              </div>
              <div class="amount-received-words" style="display: flex; justify-content: flex-start; align-items: center;">
                <p style="width: 40%; font-size: 12px; font-weight: 400px; margin: 0">Amount Received In Words</p>
                <p class="dotted-underline"
                  style="width: 50%; font-size: 12px; font-weight: 700; margin: 0; color: var(--primary-color);">
                  ${
                    !isNaN(data.bookingData?.paid_amount)
                      ? numWords(data.bookingData?.paid_amount).replace(
                          /\b\w/g,
                          (char) => char.toUpperCase()
                        ) + " Only"
                      : "-"
                  }
                </p>
              </div>

              <div class="amount-received-words" style="display: flex; justify-content: flex-start; align-items: center;">
                <p style="width: 40%; font-size: 12px; font-weight: 400px; margin: 0">Payment Mode</p>
                <p class="dotted-underline"
                  style="width: 50%; font-size: 12px; font-weight: 700; margin: 0; color: var(--primary-color);">
                  ${data?.bookingData.payment_type}</p>
              </div>

              <div class="customer-value" style="width: 50%;">
              </div>
            </div>

            <div class="received-amount" style="display: flex; justify-content: flex-end; padding-top: 12px;">
              <div
                style="background-color: #f0f0f0;border-radius: 4px; width: 224px; height: 36px;display: flex; text-align: center;justify-content: center;align-items: center;">
                <p style="font-size: 14px;">Received Amount <span style="font-size: 14px; font-weight: 700; color: var(--primary-color);">₹${
                  data.bookingData?.paid_amount || 0
                } <span> </p>
              </div>
            </div>

            <div class="signatures" style="display: flex; justify-content:space-between; padding-top: 40px;">
              <p style="font-size: 12px; font-weight: 400;">Customer Signature</p>
              <p style="font-size: 12px; font-weight: 400;">Cashier Signature</p>
            </div>

          </div>
        </div>
      </div>
    </body>
    </html>`;

    return template;
  };

  generateInvoiceTemplate = (data, invoiceData) => {
    try {
      const getSubtotal = (invoiceTotalPrice, invoiceTaxes) => {
        let invoiceItemTotalPrice = new Decimal(invoiceTotalPrice || 0);
        let invoiceTaxesTotal = invoiceTaxes.reduce(
          (sum, tax) =>
            sum.plus(
              new Decimal(tax?.invoice_tax_amount || tax?.addon_tax_amount)
            ),
          new Decimal(0)
        );
        let subtotal = invoiceItemTotalPrice.plus(invoiceTaxesTotal);
        return Number(subtotal).toFixed(2);
      };

      const {
        invoice_number,
        customer_name,
        invoice_date,
        invoice_name,
        invoice_mobile,
        invoice_address,
        invoice_items,
        gstin_number,
        supplier_gstin_number,
        supplier_hsn_sac,
        total_amount,
        round_off_value,
        round_off_amount,
      } = invoiceData || {};

      const {
        hotel_logo,
        hotel_name,
        hotel_address,
        hotel_email,
        hotel_contact_number,
      } = data?.userData || {};
      const template = `
      <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta http-equiv="X-UA-Compatible" content="ie=edge" />
    <link rel="icon" href="./favicon.ico" type="image/x-icon" />
    <style>
      /* CSS Reset */
      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
      
      /* CSS Variables for Colors */
      :root {
        --primary-color: #3a3a3a;
        --secondary-color: #3a3a3a;
        --tertiary-color: #858585;
        --border-color: #d7d7d7;
        --background-color: #F3F3F3;
      }

      body {
        font-family: Arial, sans-serif;
        background-color: #ffffff !important;
      }

      .container {
        height: 97.2vh;
        width: 90%;
        margin: 1% auto 0 auto;
      }

      .header {
        display: flex;
        justify-content: space-between;
      }

      .hotel-info {
        width: 65%;
      }

      .hotel-logo {
        height: 70px;
        width: 70px;
        border-radius: 3px;
      }

      .hotel-name {
        font-size: 16px;
        font-weight: 700;
        color: var(--primary-color);
        margin: 2% 0;
      }

      .hotel-address {
        color: var(--secondary-color);
        font-size: 12px;
        font-weight: 400;
        max-width: 230px;
        line-height: 1.5;
      }

      .contact-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        margin-top: 1%;
      }

      .contact-item {
        display: flex;
        align-items: center;
        padding: 0;
        width: 30%;
        justify-content: space-between;
      }

      .contact {
        margin-top : 3%; 
        color: var(--secondary-color);
        font-size: 12px;
        font-weight: 400;
      }

      .contact tr td {
        padding-top: 2%;
        padding-bottom: 2%;
      }

      .contact-label {
        color: var(--tertiary-color);
        font-size: 12px;
        font-weight: 400;
        margin: 0;
      }

      .contact-value {
        color: var(--secondary-color);
        font-size: 12px;
        font-weight: 400;
        margin: 0;
      }

      .bill-info {
        width: 35%;
      }
      
      .bill-name {
        color: var(--secondary-color);
        font-size: 12px;
        font-weight: 400;
        max-width: 230px;
        margin : 1% 0 1% 0;
      }

      .bill-address {
        color: var(--secondary-color);
        font-size: 12px;
        font-weight: 400;
        max-width: 230px;
        line-height: 1.5;
      }

      .bill-header {
        font-size: 28px;
        font-weight: 500;
        color: var(--primary-color);
      }

      .invoice-info {
        font-size: 12px;
        font-weight: 500;
      }

      .invoice-info tr td {
        padding-top: 2%;
        padding-bottom: 2%;
      }

      .divider {
        border: 1px solid var(--border-color);
        margin: 1% 0
      }

      .bill-to {
        font-size: 14px;
        font-weight: 700;
        color: var(--primary-color);
        padding-top: 1%;
        margin-bottom: 1%;
      }

      .table-container {
        display: flex;
        justify-content: right;
        width: 98%;
      }

      .invoice-table {
        width: 100%;
        border: 1px solid #e9e9e9;
        border-collapse: collapse;
        font-size: 12px;
        margin-top: 5%;
      }

      .invoice-table th,
      .invoice-table td {
        border: 1px solid #e9e9e9;
        padding: 5px;
        text-align: left;
      }

      .invoice-table th {
        background-color: var(--primary-color) !important;
        color: #ffffff;
        font-weight: 500;
      }

      .subtotal-row {
        text-align: right;
      }

      .summary-table {
        margin-top: 3%;
        background-color:  #f0f0f0 !important;
        font-size: 12px;
        padding: 10px;
        border-radius: 4px;
      }

       @media print {
            .invoice-table th {
                background-color: var(--primary-color) !important;
                -webkit-print-color-adjust: exact; /* For Safari and Chrome */
                print-color-adjust: exact; /* For Firefox */
            }
            .summary-table {
                background-color:  #f0f0f0 !important;
                -webkit-print-color-adjust: exact; /* For Safari and Chrome */
                print-color-adjust: exact; /* For Firefox */
            }
        }
      .footer {
        margin-top: 20%;
      }

      .footer-text {
        text-align: center;
        margin-bottom: 10%;
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-color);
      }

      .signature-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #252525;
      }

      .child-row td {
    padding-left: 20px;  /* Indent child rows */
}

       
    </style>
  </head>

  <body>
    <div class="container">
      <div class="header">
        <div class="hotel-info">
          <img id="hotel-logo-image" class="hotel-logo" src=${hotel_logo} alt="" />
          <p class="hotel-name">${hotel_name}</p>
          <p class="hotel-address">${hotel_address}</p>
        <table class="contact">
          <tbody>
            <tr>
              <td>Contact</td>
              <td>: ${hotel_contact_number}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>: ${hotel_email}</td>
            </tr>
            <tr>
              <td>GSTIN</p>
              <td>: ${supplier_gstin_number ? supplier_gstin_number : "-"}</td>
            </tr>
          </tbody>
          </table>
        </div>
        <div class="bill-info">
         <p class="bill-header">${data?.billHeader}</p>
          <table class="invoice-info">
          <tbody>
          <tr>
          <td>Invoice.No</td>
          <td>: ${invoice_number || "-"}</td>
          </tr>
          <tr>
          <td>
            Invoice date
          </td> 
          <td>
            : ${
              this.getLocalTimeFromUtc(
                invoice_date?.replace("T", " ").split(".")[0]
              ).invoice_date
            }
          </td>
          </tr>
          </tbody>
          </table>
        </div>
      </div>
      <hr class="divider" />

      <div class="bill-to-section">
        <p class="bill-to">BILL TO</p>
         ${!!customer_name
             ? `
             <p class="bill-name">${customer_name}</p>`
             : ""
         }
        <p class="bill-name">${invoice_name}</p>
        <p class="bill-address">${invoice_address}</p>
        <div class="contact-info">
          <div class="contact-item">
            <p class="contact-label">Contact : ${invoice_mobile}</p>
          </div>
          ${
            !!gstin_number
              ? `<div class="contact-item">
            <p class="contact-value">GSTIN : ${gstin_number}</p>
          </div>`
              : ""
          }
        </div>
      </div>

      <div>
        <table class="invoice-table">
          <thead>
            <tr>
              <th style="text-align: center;">S.No</th>
              <th>Description</th>
              <th>HSN/<br/>
              SAC </th>
              <th>Base price</th>
              <th>Days</th>
              <th>SGST</th>
              <th>CGST</th>
              <th>Net rent</th>
            </tr>
          </thead>
          <tbody>
            ${invoice_items
              ?.map((room, ind) => {
                // Generate rows for extra persons (addons)
                const extraPersonsRows =
                  room?.invoice_item_addons
                    ?.map((per, perInd) => {
                      return `
                <tr>
                    <td>&nbsp;&nbsp;</td>
                    <td>${perInd + 1}</td>
                    <td></td>
                    <td>₹ ${parseFloat(per.addon_price).toFixed(2)}</td>
                    <td>${per?.stay_days}</td>
                    ${per?.invoice_item_addon_taxes
                      ?.map((tax) => {
                        return `<td>₹ ${Number(tax?.addon_tax_amount).toFixed(
                          2
                        )}<br/>${tax?.addon_tax_rate}%</td>`;
                      })
                      .join("")}
                    <td>₹ ${getSubtotal(
                      per?.addon_total_price,
                      per?.invoice_item_addon_taxes
                    )}</td>
                </tr>`;
                    })
                    .join("") || "";

                // Include Extra Persons section if there are addons
                const extraPersonsSection = room?.invoice_item_addons?.length
                  ? `
            <tr>
                <td colspan="9" style="font-weight: bold;">Extra Persons</td>
            </tr>
            ${extraPersonsRows}
        `
                  : "";

                return `
            <tr>
                <td style="text-align: center;">${ind + 1}</td>
                <td>Room - ${room?.room_id?.room_no}<br/> ${
                  room?.room_id?.room_type_id?.room_type_name
                }<br />
                ${format(
                  this.getLocalTimeFromUtc(
                    room.checkin_date?.replace("T", " ").split(".")[0]
                  ).dateTimeString,
                  "dd-MM-yyyy hh:mm a"
                )}<br />
                ${format(
                  this.getLocalTimeFromUtc(
                    room.checkout_date?.replace("T", " ").split(".")[0]
                  ).dateTimeString,
                  "dd-MM-yyyy hh:mm a"
                )}
                </td>
                <td>${supplier_hsn_sac}</td>
                
                <td>₹ ${parseFloat(room.invoice_item_price)}</td>
                <td>${room?.stay_days}</td>
                ${room?.invoice_taxes
                  ?.map((tax) => {
                    return `<td>₹ ${Number(tax?.invoice_tax_amount)}<br/>${
                      tax?.tax_rate
                    }%</td>`;
                  })
                  .join("")}
                <td>₹ ${getSubtotal(
                  room?.invoice_item_total_price,
                  room?.invoice_taxes
                )}</td>
              </tr>
              ${extraPersonsSection}
             
              `;
              })
              .join("")}
         <tr>
                <td colspan="6"></td>
                <td style="color: #252525;">Subtotal</td>
                <td style="color: #3D3D3D; font-weight:600;">₹ ${parseFloat(
                  total_amount
                )}</td>
              </tr>
          </tbody>
        </table>

        <div class="table-container">
          <table class="summary-table">
            <tbody>
        ${
          Math.abs(parseFloat(round_off_value)) > 0
            ? `<tr>
          <td>Rounding off</td>
          <td style="padding-left: 5px;color: var(--primary-color); text-align: right; font-weight: 600">${Math.abs(
            parseFloat(round_off_value)
          )}</td>
        </tr>`
            : ""
        }
              <tr>
                <td style="padding-right: 10px;">Total </td>
                <td style="padding-left: 10px;color: var(--primary-color); text-align: right; font-weight: 600">₹ ${
                  this.roundOff(round_off_amount) || 0
                }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      ${
        invoice_items.length > 8 ||
        (invoice_items.length > 3 && invoice_items.length <= 4)
          ? '<div style="page-break-after: always;"></div>'
          : ""
      }  
      <div class="footer">
        <div class="footer-text">
          <p>
            Regardless of the billing instruction, you are held personally liable
            for the total amount of this bill.
          </p>
        </div>

        <div class="signature-section">
          <p>Customer Signature</p>
          <p>Cashier Signature</p>
        </div>
      </div>
    </div>
  </body>
</html>
    `;

      return template;
    } catch (error) {
      console.error("error in generate invoice template:", error);
    }
  };

  newPrintPaymentReceipt(billData) {
    try {
      const helper = new Helper();
      let printableTemplate = "";

      if (billData?.billHeader === "INVOICE") {
        billData["billHeader"] = "TAX INVOICE";
        billData.invoiceData.forEach((invoiceDatum, ind) => {
          printableTemplate += helper.generateInvoiceTemplate(
            billData,
            invoiceDatum
          );
          printableTemplate += '<div style="page-break-after: always;"></div>'; // Add page break
        });
      } else {
        printableTemplate = helper.generateReceiptTemplate(billData);
      }

      // Step 1: Open a new window
      const printWindow = window.open("", "_blank");
      if (!printWindow) {
        throw new Error(
          "Failed to open new window. Please check your browser settings."
        );
      }

      // Step 2: Write the HTML content to the new window
      printWindow.document.open();
      printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              .page-break {
                page-break-after: always;
              }
            }
          </style>
        </head>
        <body>
          ${printableTemplate}
        </body>
      </html>
    `);
      printWindow.document.close();

      // Step 3: Function to check if all images are loaded
      function allImagesLoaded() {
        try {
          const images = printWindow.document.getElementsByTagName("img");
          for (let i = 0; i < images.length; i++) {
            if (!images[i].complete) {
              return false;
            }
          }
          return true;
        } catch (error) {
          console.error("Error checking image load status:", error);
          return false;
        }
      }

      // Step 4: Wait for images to load and then trigger print
      function checkAndPrint() {
        try {
          if (allImagesLoaded()) {
            printWindow.print();
          } else {
            setTimeout(checkAndPrint, 100); // Check every 100ms
          }
        } catch (error) {
          console.error("Error during print operation:", error);
        }
      }

      // Step 5: Initiate the check
      checkAndPrint();
    } catch (error) {
      console.error("An error occurred in print template:", error);
      alert("An error occurred while preparing the print. Please try again.");
    }
  }

  // printTableData = (tableType, records, userData) => {
  //   let printableTemplate = this.generateTableData(tableType, records, userData);
  //   let iframe = document.createElement("iframe");
  //   iframe.setAttribute("style", "visibility: hidden;");
  //   document.body.appendChild(iframe);
  //   let iframeDoc = iframe.contentWindow.document;
  //   iframeDoc.open();
  //   iframeDoc.write(printableTemplate);
  //   iframeDoc.close();
  //   iframe.onload = function () {
  //     iframe.contentWindow.print();
  //     document.body.removeChild(iframe);
  //   };
  // };

  printTableData = (tableType, allFilter, records, userData) => {
    let printableTemplate = this.generateTableData(
      tableType,
      allFilter,
      records,
      userData
    );

    const printWindow = window.open("", "Print Table");
    printWindow.document.write(printableTemplate);
    printWindow.document.close();
    printWindow.focus();

    function allImagesLoaded() {
      try {
        const images = printWindow.document.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
          if (!images[i].complete) {
            return false;
          }
        }
        return true;
      } catch (error) {
        console.error("Error checking image load status:", error);
        return false;
      }
    }
    function checkAndPrint() {
      try {
        if (allImagesLoaded()) {
          printWindow.print();
          printWindow.close();
        } else {
          setTimeout(checkAndPrint, 100);
        }
      } catch (error) {
        console.error("Error during print operation:", error);
      }
    }
    checkAndPrint();
  };

  generateTableData = (tableType, allFilter, records, userData) => {
    let serialNumber = 0;
    const tableRows = records?.reduce(
      (acc, e) =>
        acc +
        e?.booked_rooms.reduce((innerAcc, room, roomInd) => {
          serialNumber++;
          return (
            innerAcc +
            `
          <tr>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">
              ${serialNumber}
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">
              ${room?.room_no}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
              ${e?.name}
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 6px;">
              ${e?.mobile}
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">
              ${e?.occupation || ""}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
              ${e?.address}, ${e?.city_name}, ${e?.state_name}.
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">
              ${room?.no_of_adults + room?.no_of_children}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
              ${room?.purpose_of_visit || "-"}
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; white-space: nowrap;">
              ${this.getLocalTimeFromUtc(room?.checkin_date)?.date} ${
              this.getLocalTimeFromUtc(room?.checkin_date)?.time
            }<br/>
            ${
              tableType === "departure" || allFilter === "DEPARTURE"
                ? `to <br/> ${
                    this.getLocalTimeFromUtc(room?.actual_checkout_date)?.date
                  } ${
                    this.getLocalTimeFromUtc(room?.actual_checkout_date)?.time
                  }`
                : ""
            }
            </td>
            ${
              tableType === "departure" || allFilter === "DEPARTURE"
                ? `
            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
              ${room?.heading_to || "-"}
            </td>`
                : ""
            }
            <td style="border: 1px solid #dddddd; text-align: center; padding: 8px;">
              <img src="${
                e?.checkin_signature_image?.[0]?.url ||
                e?.checkin_signature_image?.[0]?.url
              }" alt="" style="width: 60px; height: 60px;"/>
            </td>
          </tr>`
          );
        }, ""),
      ""
    );

    return `
      <html>
        <body>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0.2%;">
          <h3>${
            tableType === "all" && allFilter !== "ALL"
              ? `${tableType?.toUpperCase()}-${allFilter.toUpperCase()}`
              : tableType?.toUpperCase()
          }</h3>
            <div style="cursor: pointer" onclick="window.close()">[X]</div>
          </div>
          <div style="text-align: center;">
            <h1 style="letter-spacing: 1px;">${userData.hotel_name}</h1>
            <h3>${userData.hotel_address}</h3>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center; padding: 0.5%; margin: 2% 0;">
            <div style="text-align: left;">Date: ${moment().format(
              "DD/MM/YYYY"
            )}</div>
            <div style="text-align: left;">Contact No: ${
              userData?.hotel_contact_number
            }</div>
            <div style="text-align: left;">Email: ${userData?.hotel_email}</div>
          </div>
          <table id="table" style="border-collapse: collapse; width: 100%; page-break-after: auto;">
            <tr style="border-bottom: 2px solid black; border-top: 2px solid black; page-break-inside: avoid; page-break-after: auto;">
              <th style="border: 1px solid #dddddd; text-align: center; padding: 8px;">S.No</th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; white-space: nowrap;">Room No.</th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 6px;">Mobile</th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Occupation</th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; min-width: 180px !important;">Address</th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 8px;">Pax</th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; white-space: nowrap;">Purpose</th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 5px; white-space: nowrap;">Date</th>
              ${
                tableType === "departure" || allFilter === "DEPARTURE"
                  ? `
          <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; white-space: nowrap;">Heading To</th>`
                  : ""
              }
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; min-width: 100px !important;">Signature</th>
            </tr>
            ${tableRows}
          </table>
        </body>
      </html>`;
  };

  getUTCDateTime = (localTime) => {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!localTime) {
        return null;
      }
      const utcTime = fromZonedTime(localTime, timezone);
      return utcTime;
    } catch (error) {
      throw error;
    }
  };

  getLocalTimeFromUtc = (utcTime) => {
    try {
      const utcDateTime = utcTime?.replace(" ", "T").split(".")[0] + "Z";
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTime = toZonedTime(utcDateTime, timeZone);
      const dateString = format(localTime, "yyyy-MM-dd");
      const dateTimeString = format(localTime, "yyyy-MM-dd HH:mm:ss");
      const date = format(localTime, "MMM dd");
      const time = format(localTime, "hh:mm a");
      const invoice_date = format(localTime, "dd-MM-yyyy");
      return {
        date,
        time,
        dateString,
        dateTimeString,
        localTime,
        invoice_date,
      };
    } catch (error) {
      console.error(error);
    }
  };

  calculateStayDays = (expected_checkin_date, expected_checkout_date) => {
    const checkinDate = new Date(
      new Date(expected_checkin_date).setHours(0, 0, 0, 0)
    );
    const checkoutDate = new Date(
      new Date(expected_checkout_date).setHours(0, 0, 0, 0)
    );

    const stayDays = Math.ceil(
      (checkoutDate.getTime() - checkinDate.getTime()) / (24 * 60 * 60 * 1000)
    );

    return !!stayDays || stayDays === 0 ? stayDays + 1 : 0;
  };

  getDaysDifferenceByStayType = (
    startDate,
    endDate,
    stayType,
    graceHours = 0
  ) => {
    try {
      let startDateIso = parseISO(
        format(startDate, "yyyy-MM-dd HH:mm:ss").replace(" ", "T")
      );
      let endDateIso = parseISO(
        format(endDate, "yyyy-MM-dd HH:mm:ss").replace(" ", "T")
      );

      if (stayType === STAY_TYPES.HOUR || stayType === STAY_TYPES.DAY) {
        const dayStayMinDiff = differenceInMinutes(endDateIso, startDateIso);
        const adjustedHours = dayStayMinDiff - graceHours * 60;
        return Math.ceil(adjustedHours / 1440);
      } else if (stayType === STAY_TYPES.NOON) {
        const noonStartDate = addHours(startOfDay(startDateIso), 12);
        let beforeDay;
        const isBeforeNoon =
          startDateIso < noonStartDate && endDateIso > noonStartDate;
        if (isBeforeNoon) {
          let beforeDayDiffMin = differenceInMinutes(
            noonStartDate,
            startDateIso
          );
          let beforeDayDiff = Math.ceil(beforeDayDiffMin / 1440);
          beforeDay = beforeDayDiff > 0 ? beforeDayDiff : 1;
        }
        if (startDateIso > noonStartDate) {
          startDateIso = noonStartDate;
        }

        const totalMinutes = differenceInMinutes(endDateIso, noonStartDate);
        const graceMinutes = graceHours * 60;
        const adjustedMinutes = totalMinutes - graceMinutes;
        const days = Math.ceil(adjustedMinutes / 1440);

        return (
          (isBeforeNoon ? days : days > 0 ? days : 1) +
          (!!beforeDay ? beforeDay : 0)
        );
      } else {
        throw new Error("Enter valid stay type");
      }
    } catch (error) {
      throw error;
    }
  };

  // debounce.js
  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  getPayloadFromToken = (token) => {
    try {
      if (!!token) {
        return decodeJwt(token);
      } else {
        return null;
      }
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  };

  getAuthorizedObjects(componentObject, currentRole) {
    return componentObject.filter(
      (option) =>
        option.authorizedRoles && option.authorizedRoles.includes(currentRole)
    );
  }

  sortByParentKey(roomsArray) {
    return roomsArray.sort((a, b) => {
      // Group related records together based on their parent_occupancy_key or occupancy_key
      const aKey = a.parent_occupancy_key || a.occupancy_key;
      const bKey = b.parent_occupancy_key || b.occupancy_key;

      // First, sort by group key (aKey and bKey)
      if (aKey !== bKey) {
        return aKey.localeCompare(bKey); // Using localeCompare for string comparison (UUIDs)
      }

      // If both belong to the same group, sort by occupancy_id in descending order
      return b.occupancy_id - a.occupancy_id;
    });
  }

  filterInvalidKeys (sourceObject) {
    const result = {};
    for (const key in sourceObject) {
        const value = sourceObject[key];
        if (value !== null && value !== undefined && value !== '' && value !== ' ') {
            result[key] = value;
        }
    }
    return result;
}
}
