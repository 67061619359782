import axios from 'axios';
import { END_POINT } from '../utils/End-points';
import { errorToster } from '../shared/toster/Toster';
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../core/store/redux/slices/Auth-slice';

const axiosInstance = axios.create({
  baseURL: END_POINT.BASE_URL,
  withCredentials: true,
});

const formDataHeaders = {
  'Content-Type': 'multipart/form-data',
  'timezone': 'Asia/Kolkata'
}

const jsonHeaders = {
  'Content-Type': 'application/json',
  'timezone': 'Asia/Kolkata'
}



let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};


axiosInstance.interceptors.response.use(
  response => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest?._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axiosInstance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axiosInstance.post(END_POINT.AUTH.REFRESH_TOKEN)
          .then(({ data }) => {
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
            originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
            processQueue(null, data.accessToken);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => { isRefreshing = false; });
      });
    }

    return Promise.reject(error);
  }
);





const ApiClient = () => {
  const { hotel } = useSelector(selectAuthData);

  axiosInstance.defaults.headers.common['hotel_id'] = hotel?.hotel_id;


  const get = async (url, params, errMsg) => {
    try {
      const response = await trackPromise(axiosInstance.get(url, {
        headers: jsonHeaders
      }));

      return response;
    } catch (error) {
      console.error('Error making GET request:', error);
      throw error
    }
  }

  const post = async (url, data, errMsg, query = null) => {
    try {
      let endpoint = url
      if (query) {
        const queryString = new URLSearchParams(query).toString();
        endpoint = `${endpoint}?${queryString}`
      }
      const response = await trackPromise(axiosInstance.post(endpoint, data, {
        headers: jsonHeaders
      }));
      return response;
    } catch (error) {
      console.error('Error making POST request:', error);
      let msg = error?.response?.data?.msg || errMsg
      // errorToster(msg)
      throw error
    }
  };

  const delet = async (url, params, data, errMsg) => {
    try {
      const endpoint = !!params ? `${url}/${params}` : url
      const requestData = !!data ? { data } : null
      const response = await trackPromise(axiosInstance.delete(endpoint, requestData, {
        headers: jsonHeaders
      }));
      return response;
    } catch (error) {
      console.error('Error making DELETE request:', error);
      errMsg && errorToster(errMsg);
    }
  };

  const put = async (url, data, params, errMsg) => {
    try {
      const endpoint = !!params ? `${url}/${params}` : url
      const response = await trackPromise(axiosInstance.put(endpoint, data, {
        headers: jsonHeaders
      }));
      return response;
    } catch (error) {
      console.error('Error making UPDATE request:', error);
      // errMsg && errorToster(errMsg);
      throw error
    }
  };

  const putFormData = async (url, data, params, errMsg) => {
    try {
      const endpoint = !!params ? `${url}/${params}` : url
      const response = await trackPromise(axiosInstance.put(endpoint, data, {
        headers: formDataHeaders
      }));
      return response;
    } catch (error) {
      console.error('Error making UPDATE request:', error);
      errMsg && errorToster(errMsg);
    }
  };

  const postFormData = async (url, data, errMsg) => {
    try {
      const response = await trackPromise(axiosInstance.post(url, data, {
        headers: formDataHeaders
      }));
      return response;
    } catch (error) {
      console.error('Error making POST request:', error);
      let msg = error?.response?.data?.msg || errMsg
      errorToster(msg)
    }
  };

  const setRequestHeader = (key, value) => {
    try {
      axiosInstance.defaults.headers.common[key] = value;
    } catch (error) {
      console.error('Error Setting Axios Header:', error);
      let msg = error?.response?.data?.msg || 'Error Setting Axios Header'
      errorToster(msg)
    }
  };

  return { api: { get, post, delet, put, postFormData, putFormData, setRequestHeader } };

}

export default ApiClient;
