import * as Yup from "yup";

const RecordPaymentConfig = (pendingAmount, message) =>
  Yup.object().shape({
    payment_date: Yup.mixed(),
    payment_type_id: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required("Payment Mode is required"),
    paid_amount: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(message || "Amount is required")
      .test(
        "max-paid-amount",
        "Amount can't exceed the payment due.",
        function (value) {
          return pendingAmount === null || pendingAmount === undefined || value <= pendingAmount;
        }
      ),
    remarks: Yup.string(),
  });

export default RecordPaymentConfig;