import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ApiClient from "../../../apis/Api-client";
import { END_POINT } from "../../../utils/End-points";
import { useSelector } from "react-redux";
import {
  selectTriggered,
  setTriggered,
} from "../../../core/store/redux/slices/DashboardApi-slice";
import { useDispatch } from "react-redux";
import { errorToster, successToster } from "../../../shared/toster/Toster";
import moment from "moment/moment";
import RecordPaymentConfig from "../../../config/forms/RecordPayment-config";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { format } from "date-fns-tz";
import { Helper } from "../../../utils/Helpers";
const { roundOff, debounce } = new Helper();

const RecordPayment = ({
  bookingData,
  handleCloseModal = () => {},
  handleClosePopover = () => {},
  resetData = () => {},
}) => {
  const { api } = ApiClient();
  const isTriggered = useSelector(selectTriggered);
  const dispatch = useDispatch();
  const [paymentsTypes, setPaymentsTypes] = useState([]);
  const [paymentDetail, setPaymentDetail] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const totalPayment =
    roundOff(Number(paymentDetail?.due_amount || 0)) +
    roundOff(Number(paymentDetail?.reservation_amount || 0));

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(RecordPaymentConfig(totalPayment)),
  });

  const { paid_amount, payment_type_id, remarks } = watch();

  const themeStyles = useTheme();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;

  const debouncedcreatePaymentData = useCallback(
    debounce((data) => {
      createPaymentData(data);
    }, 1000),
    []
  );

  const onSubmit = (data) => {
    debouncedcreatePaymentData(data);
  };

  useEffect(() => {
    if (!!bookingData?.booking_id) {
      setInitialData(bookingData?.booking_id);
    }
  }, [bookingData?.booking_id]);

  const setInitialData = async (booking_id) => {
    try {
      const [paymentTypes, paymentDetail] = await Promise.all([
        api
          .get(END_POINT.PAYMENT.GET_ALL_PAYMENT_TYPE)
          .then((res) => res.data.data || [])
          .catch(() => []),
        api
          .get(
            `${END_POINT.PAYMENT.GET_PAYMENT_DETAIL_BY_BOOKING}${booking_id}`
          )
          .then((res) => res.data.data || null)
          .catch(() => null),
      ]);
      setPaymentDetail(paymentDetail);
      setPaymentsTypes(paymentTypes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const createPaymentData = async (data) => {
    try {
      setIsSubmitted(true);
      let paymentData = {
        booking_id: bookingData.booking_id,
        payment_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        paid_through: "Record Payment",
        paid_amount: data.paid_amount,
        payment_type_id: data.payment_type_id,
        remarks: data.remarks,
      };
      let paymentRes = await api.post(
        END_POINT.PAYMENT.CREATE_PAYMENT,
        paymentData
      );

      if (paymentRes?.status === 200) {
        handleClosePopover();
        resetData();
        reset();
        dispatch(setTriggered(!isTriggered));
        successToster(
          <>
            Payment by{" "}
            <span style={{ fontWeight: 700 }}>{bookingData?.name}</span>{" "}
            successfully saved.
          </>
        );
      }
    } catch (error) {
      errorToster("Something went wrong!");
    } finally {
      setTimeout(() => {
        setIsSubmitted(false);
      }, 2000);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        columnGap={"2.8%"}
        rowGap={{ xs: "3vw", sm: 0 }}
        sx={{
          width: { xs: "88vw", sm: "69.23vw", md: "40vw" },
          padding: "3% 5%",
        }}
      >
        <Grid item xs={6.5} sm={5.83} sx={{ marginBottom: { sm: "5%" } }}>
          <Typography
            sx={{
              fontSize: { xs: "5vw", sm: "2.4vw", md: "1.389vw" },
              color: "#252525",
              fontWeight: 700,
            }}
          >
            Record Payment
          </Typography>
        </Grid>
        <Grid item xs={5} sm={5.83}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "4.1vw", sm: "1.916vw", md: "1.111vw" },
                color: primaryColor,
                fontWeight: 600,
              }}
            >
              {bookingData?.name}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.83}
          sx={{ marginBottom: { xs: "1%", sm: "6%" } }}
        >
          <FormControl fullWidth>
            <TextField
              value={moment().format("MM/DD/YYYY")}
              size="small"
              disabled
              label="Date"
              {...register("payment_date")}
              InputProps={{
                readOnly: true,
                sx: {
                  fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
                  color: "#727272",
                },
              }}
              InputLabelProps={{
                sx: { fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } },
              }}
              FormHelperTextProps={{
                sx: {
                  fontSize: { xs: "3.08vw", sm: "1.439vw", md: "0.833vw" },
                },
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5.83} sx={{ marginBottom: { sm: "1%", md: 0 } }}>
          <TextField
            label="Amount"
            defaultValue={paid_amount}
            variant="outlined"
            type="number"
            size="small"
            className="NoSpinnerTextField no-spinner"
            onInput={(e) => {
              let { value } = e.target;
              e.target.value =
                e.target.value == 0 ? null : value.replace(/[^0-9]/g, "");
            }}
            inputProps={{ min: 1 }}
            fullWidth
            error={!!errors["paid_amount"] && !!errors["paid_amount"]?.message}
            helperText={
              errors["paid_amount"]?.message
                ? errors["paid_amount"]?.message
                : ""
            }
            {...register("paid_amount", { required: true })}
            InputProps={{
              sx: {
                fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
                color: "#727272",
              },
            }}
            InputLabelProps={{
              sx: { fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } },
            }}
            FormHelperTextProps={{
              sx: { fontSize: { xs: "3.08vw", sm: "1.439vw", md: "0.833vw" } },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={5.83} sx={{ margin: { xs: "1.3% 0", sm: 0 } }}>
          <FormControl fullWidth>
            <TextField
              select
              defaultValue={payment_type_id}
              size="small"
              label="Select Payment Mode"
              {...register("payment_type_id", { required: true })}
              error={
                !!errors["payment_type_id"] &&
                !!errors["payment_type_id"]?.message
              }
              helperText={
                errors["payment_type_id"]?.message
                  ? errors["payment_type_id"]?.message
                  : ""
              }
              InputProps={{
                sx: {
                  fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
                  color: "#727272",
                },
              }}
              InputLabelProps={{
                sx: { fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } },
              }}
              FormHelperTextProps={{
                sx: {
                  fontSize: { xs: "3.08vw", sm: "1.439vw", md: "0.833vw" },
                },
              }}
            >
              {paymentsTypes?.map((option) => (
                <MenuItem
                  key={option.payment_type_id}
                  value={option.payment_type_id}
                  sx={{
                    fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
                  }}
                >
                  {option.payment_type}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5.83}>
          <TextField
            label="Remarks"
            defaultValue={remarks}
            variant="outlined"
            type="text"
            size="small"
            onInput={(e) => {
              let { value } = e.target;
              e.target.value = value.replace(/^\s+/, "");
            }}
            fullWidth
            helperText={" "}
            {...register("remarks")}
            InputProps={{
              sx: {
                fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
                color: "#727272",
              },
            }}
            InputLabelProps={{
              sx: { fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } },
            }}
            FormHelperTextProps={{
              sx: { fontSize: { xs: "3.08vw", sm: "1.439vw", md: "0.833vw" } },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ margin: "0% 0 3.5% 0" }}>
          <Typography
            sx={{ fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" } }}
          >
            Payment Due :{" "}
            <span style={{ fontWeight: 700 }}>
              {totalPayment ? `₹${totalPayment}` : "N/A"}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={5.83}>
          <Button
            fullWidth
            onClick={handleCloseModal}
            variant="outlined"
            sx={{
              color: primaryColor,
              border: `1px solid ${primaryColor}`,
              fontWeight: 700,
              fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
            }}
          >
            CANCEL
          </Button>
        </Grid>

        <Grid xs={5.83}>
          <Button
            fullWidth
            disabled={!paid_amount || isSubmitted}
            variant="contained"
            type="submit"
            sx={{
              color: contrastText,
              backgroundColor: primaryColor,
              fontWeight: 700,
              fontSize: { xs: "4.1vw", sm: "1.918vw", md: "1.111vw" },
            }}
          >
            SAVE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default RecordPayment;
