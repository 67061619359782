import { createSlice } from "@reduxjs/toolkit";

const reservationSlice = createSlice({
    name: "reservation",
    initialState: {
        roomTypeData: null,
        isQuickReservationOpen: false,
        isGetRevervationTriggered: true
    },
    reducers: {
        setReservationData: (state, action) => {
            state.reservationData = action.payload;
        },
        clearReservationData: (state) => {
            state.reservationData = null;
        },
        setOpenQuickReservation: (state, action) => {
            state.isQuickReservationOpen = action.payload
        },
        setIsGetRevervationTriggered: (state, action) => {
            state.isGetRevervationTriggered = action.payload
        }
    },
});

export const { setReservationData, clearReservationData, setOpenQuickReservation } = reservationSlice.actions;
export const selectReservationData = (state) => state.reservation.reservationData;
export const isQuickReservationOpen = (state) => state.reservation.isQuickReservationOpen;
export const { setIsGetRevervationTriggered } = reservationSlice.actions;
export const selectIsGetRevervationTriggered = (state) => state.reservation.isGetRevervationTriggered;
export default reservationSlice.reducer;
