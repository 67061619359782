import { Grid } from "@mui/material";
import React from "react";
import IndividualRoomBox from "./IndividualRoomBox";
import IconConstants from "../../utils/IconConstants";
import {
  CLEANING_STATUSES,
  RESERVATION_STATUSES,
  OCCUPANCY_STATUSES,
} from "../../utils/Constants";
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;
const { CHECK_IN, CHECK_OUT, ROOM_SHIFT } = OCCUPANCY_STATUSES;
const { ASSIGNED, CLEAN } = CLEANING_STATUSES;

const IndividualRoomContainer = ({
  currentRoomType,
  currentHouseKeep,
  todaysFilter,
  rooms,
  searchText,
  searchFlag,
  selectionArray,
  handleResetFilters,
}) => {
  const roomSelector = (room) => {
    return {
      roomNo: room?.room_no,
      houseKeepId: currentHouseKeep,
      todaysFilter: todaysFilter,
      image:
        room.is_under_maintenance == true
          ? IconConstants.BlockedIcon
          : room.cleaning_status_code == CLEAN
          ? room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
            ? IconConstants.CleanedPurple
            : room.occupancy_status_code === CHECK_IN
            ? IconConstants.CleanedRed
            : IconConstants.CleanRoomsIcon
          : room.cleaning_status_code == ASSIGNED
          ? room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
            ? IconConstants.CleaningPurple
            : room.occupancy_status_code === CHECK_IN
            ? IconConstants.CleaningRed
            : IconConstants.CleaningGreen
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? IconConstants.UncleanedPurple
          : room.occupancy_status_code === CHECK_IN
          ? IconConstants.UnCleanRoomsIcon
          : IconConstants.UncleanedGreen,
      roomStatusIcon:
        room.is_under_maintenance == true
          ? IconConstants.BlockedIconWhite
          : todaysFilter == "Arrival"
          ? room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
            ? IconConstants.ExpecArrivalYellow
            : IconConstants.ExpecArrivalRed
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? IconConstants.ExpecDepartYellow
          : room.occupancy_status_code === CHECK_IN
          ? IconConstants.ExpecDepartRed
          : IconConstants.ExpecDepartGreen,
      color:
        room.is_under_maintenance == true
          ? "#252525"
          : room.occupancy_status_code === CHECK_IN
          ? "#ED705B"
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? "#9747FF"
          : [CHECK_OUT, ROOM_SHIFT].includes(room.occupancy_status_code)
          ? "#62BA60"
          : "",
      nameColor:
        room.is_under_maintenance == true
          ? "#FFFFFF"
          : room.occupancy_status_code === CHECK_IN
          ? "#ED705B"
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? "#9747FF"
          : "",
      backgroundColor: "#FFFFFF",
      filteredBackgroundColor:
        room.is_under_maintenance == true
          ? "#CECECE"
          : !!currentHouseKeep || !!todaysFilter
          ? "#FFFFFF"
          : room.occupancy_status_code === CHECK_IN
          ? "#ED705B"
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? "#9F70FD"
          : [CHECK_OUT, ROOM_SHIFT].includes(room.occupancy_status_code)
          ? "#62BA60"
          : "#FFFFFF",
      flag:
        room.is_under_maintenance == true
          ? "under_maintenance"
          : room.occupancy_status_code === CHECK_IN
          ? "Occupied"
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? "Reserved"
          : [CHECK_OUT, ROOM_SHIFT].includes(room.occupancy_status_code)
          ? "Vacant"
          : "",
      currentRoomType: currentRoomType,
      cleaningStatus:
        room.cleaning_status_code == CLEAN
          ? "Clean"
          : room.cleaning_status_code == ASSIGNED
          ? "UnderCleaning"
          : "UnClean",
      isSelected: selectionArray.some(
        (item) => parseInt(item?.room_id) === parseInt(room?.room_id)
      ),
      isAllSelected: selectionArray[0]?.isAllSelected,

      roomData: room,
      border: `1px solid ${
        room.is_under_maintenance == true
          ? "#CECECE"
          : room.occupancy_status_code === CHECK_IN
          ? "#ED705B99"
          : room.reservation_status_code === CONFIRMED ||
            room.reservation_status_code === TENTATIVE
          ? "#D9BCFF"
          : [CHECK_OUT, ROOM_SHIFT].includes(room.occupancy_status_code)
          ? "#62BA6099"
          : ""
      }`,
    };
  };
  return (
    <Grid
      container
      gap={{ xs: "2vw", sm: "2.4vw", md: "1.11vw" }}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "0.1% 0%",
      }}
    >
      {rooms?.map((room, roomIndex) => {
        return (
          <IndividualRoomBox
            key={roomIndex}
            item={roomSelector(room)}
            searchText={searchText}
            searchFlag={searchFlag}
            handleResetFilters={handleResetFilters}
          />
        );
      })}
    </Grid>
  );
};
export default IndividualRoomContainer;
